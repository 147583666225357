"use client";
import { H3_2 } from "@theme/components/typography.fontvariant";
import SectionWrapper, {
  basePx,
  SectionWrapperProps,
} from "@cc/section-wrapper";
import { EnhancedSwiperProps } from "@cc/enhanced-swiper";
import { LogoCard } from "@cc/cards/logo";
import { Autoplay } from "swiper/modules";
import { ComponentProps, ComponentType } from "react";
import { deepmerge } from "@mui/utils";
import "../../node_modules/swiper/modules/autoplay.min.css";
import EnhancedSwiper from "@cc/enhanced-swiper"

export interface PartnerSwiperSectionProps<
  T extends ComponentType<any> = ComponentType<any>,
  P extends ComponentProps<T> = ComponentProps<T>,
> extends Omit<SectionWrapperProps, "children"> {
  SwiperProps?: Omit<EnhancedSwiperProps<T, P>, "data" | "SlideComponent">;
  data?: EnhancedSwiperProps<T, P>["data"];
  SlideComponent?: EnhancedSwiperProps<T, P>["SlideComponent"];
}

const PartnerSwiperSection = <
  T extends ComponentType<any>,
  P extends ComponentProps<T>,
>(
  props: PartnerSwiperSectionProps<T, P>,
) => {
  const { navId, data, SlideComponent, SwiperProps, title, ...rest } = props;
  console.log("=>(partner-swiper-section.tsx:37) navId", navId);
  return (
    <SectionWrapper
      navId={navId || "partners"}
      SectionHeaderProps={{
        title: title,
        TitleTypographyProps: {
          variant: "h3",
          fontSize: H3_2,
          textAlign: "center",
          maxWidth: 951,
          mx: "auto",
        },
      }}
      {...deepmerge(sectionWrapperDefaultProps, rest)}
    >
      {data && (
        <EnhancedSwiper
          data={data || []}
          grabCursor
          SlideComponent={SlideComponent || LogoCard}
          {...deepmerge(swiperProps, SwiperProps)}
        />
      )}
    </SectionWrapper>
  );
};

export default PartnerSwiperSection;

const sectionWrapperDefaultProps: Omit<SectionWrapperProps, "children"> = {
  color: "primary.main",
  ContainerProps: {
    px: {
      ...basePx,
      xs: 0,
      md: 0,
    },
  },
};

const swiperProps: Omit<EnhancedSwiperProps, "data" | "SlideComponent"> = {
  slidesPerView: "auto",
  speed: 2000,
  loop: true,
  modules: [Autoplay],
  autoplay: {
    delay: 0,
    disableOnInteraction: false,
    reverseDirection: true,
  },
  SlideWrapperProps: {
    sx: {
      width: "auto",
      mr: { xs: 2, md: 3 },
    },
  },
  sx: {
    width: "100%",
    "& .swiper-wrapper": {
      transitionTimingFunction: "linear !important",
    },
  },
};
