import { StaticImageData } from "next/image";
import { EnhancedSwiperSlideComponent } from "@cc/enhanced-swiper";
import { MotionImage } from "@cc/motion-components";

export interface ArrowSlideDefaultImageProps {
  image?: string | StaticImageData;
  title?: string;
}

const ArrowSlideDefaultImage: EnhancedSwiperSlideComponent<
  ArrowSlideDefaultImageProps
> = (props) => {
  const { image, title, index } = props;
  if (!image) throw new Error("ArrowSlideDefaultImage: image is required");
  return (
    <MotionImage
      src={image}
      width={644}
      height={424}
      sx={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
      }}
      loading={"eager"}
      alt={title || `Arrow Swiper Slide ${index}`}
    />
  );
};
export default ArrowSlideDefaultImage;
