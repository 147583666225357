"use client";
import {
  ComponentProps,
  ComponentType,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SectionWrapper, { SectionWrapperProps } from "@cc/section-wrapper";
import { EnhancedSwiperProps } from "@cc/enhanced-swiper";
import StorySlide, { StorySlideProps } from "@c/home/our-stories/story-slide";
import { Navigation } from "swiper/modules";
import SwiperNavigationArrowIcon from "@cc/swiper-navigation-arrow-icon";
import { deepmerge } from "@mui/utils";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import EnhancedSwiper from "./enhanced-swiper";

export interface ScrollTriggeredSwiperSectionProps<
  T extends ComponentType<any> = ComponentType<StorySlideProps>,
  P extends ComponentProps<T> = ComponentProps<T>,
> extends SectionWrapperProps {
  data: EnhancedSwiperProps<T, P>["data"];
  SlideComponent?: T | EnhancedSwiperProps<typeof StorySlide>["SlideComponent"];
  EnhancedSwiperProps?: Partial<Omit<EnhancedSwiperProps<T, P>, "children">>;
}

const ScrollTriggeredSwiperSection = <
  T extends ComponentType<any>,
  P extends ComponentProps<T>,
>(
  props: ScrollTriggeredSwiperSectionProps<T, P>,
) => {
  const {
    data,
    SlideComponent,
    EnhancedSwiperProps,
    ...restSectionWrapperProps
  } = props;
  const sectionRef = useRef<HTMLDivElement>(null);
  const refTimeline = useRef<GSAPTimeline>();
  const [isAnimationCompleted, setIsAnimationCompleted] = useState(false);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const section = sectionRef.current;
    if (section) {
      const { offsetHeight } = section;
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: "top 15%",
          end: `+=${offsetHeight}`,
          scrub: true,
          pin: true,
          pinSpacing: true,
          onLeave: () => {
            setIsAnimationCompleted(true);
          },
        },
      });
      timeline
        .to(section, {
          clipPath:
            "polygon(49% 0%, 60% 50%, 50% 100%, 46% 100%, 56% 50%, 45% 0%)",
        })
        .to(section, {
          clipPath:
            "polygon(100% 0%, 111% 50%, 101% 100%, -10% 100%, 0% 50%, -11% 0%)",
          minWidth: "auto",
          minHeight: "auto",
          height: "auto",
          width: "auto",
        });
      refTimeline.current = timeline;
    }
    return () => {
      if (refTimeline.current) {
        refTimeline.current?.scrollTrigger?.kill();
        refTimeline.current?.kill();
        refTimeline.current?.clear();
      }
    };
  },[]);

  const isMoreThanOneSlide = useMemo(() => data.length > 1, [data.length]);
  return (
    <SectionWrapper
      navId="Stories"
      ref={sectionRef}
      color={"secondary.main"}
      initial={"initial"}
      whileInView={isAnimationCompleted ? "animate" : undefined}
      {...deepmerge(defaultSectionWrapperProps, restSectionWrapperProps)}
    >
      <EnhancedSwiper
        data={data || []}
        SlideComponent={SlideComponent || StorySlide}
        modules={[Navigation]}
        passSlideState
        loop
        navigation={{
          enabled: isMoreThanOneSlide,
          nextEl: ".swiper-next",
          prevEl: null,
        }}
        {...EnhancedSwiperProps}
      >
        {isMoreThanOneSlide && (
          <SwiperNavigationArrowIcon
            direction={"next"}
            className={"swiper-next"}
            sx={{
              right: {
                xs: 16,
                md: 40,
                lg: 64,
              },
              fontSize: { xs: 45, md: 70 },
              top: {
                xs: "18%",
                sm: "22%",
                md: "50%",
              },
            }}
          />
        )}
      </EnhancedSwiper>
    </SectionWrapper>
  );
};

export default ScrollTriggeredSwiperSection;

const defaultSectionWrapperProps: SectionWrapperProps = {
  color: "secondary.main",
  ContainerProps: {
    px: 0,
  },
  sx: {
    clipPath: "polygon(48% 25%, 55% 50%, 49% 75%, 46% 75%, 52% 50%, 45% 25%)",
  },
};
